import layout from '@/layout/index.vue'
const talent = {
	path: '/configure',
	component: layout,
	name: 'setting',
	redirect: '/setting/commonSetting',
	meta: {
		title: '配置',
		isSideBar: true
	},
	children: [{
			path: '/setting/commonSetting',
			component: () => import('@/views/setting/talentSetting/index.vue'),
			name: 'settingTalent',
			redirect: '/setting/talentSetting/allSetting',
			meta: {
				title: '通用设置'
			},
			children: [{
				path: '/setting/tagSetting/allSetting',
				component: () => import('@/views/setting/tagSetting/index.vue'),
				name: 'settingTagAllSetting',
				redirect: '/setting/tagSetting/allSetting/list',
				meta: {
					title: '标签类别'
				},
				children: [{
					path: '/setting/tagSetting/allSetting/list',
					component: () => import('@/views/setting/tagSetting/list/index.vue'),
					name: 'settingTagAllSettingList',
					meta: {
						title: '标签类别',
						isShow: true,
						activeMenu: '/setting/tagSetting/allSetting'
					},
				}]
			}]
		},
		{
			path: '/setting/authSetting',
			component: () => import('@/views/setting/userSetting/index.vue'),
			name: 'settingTalent',
			redirect: '/setting/userSetting/allSetting',
			meta: {
				title: '权限管理'
			},
			children: [{
					path: '/setting/userSetting/allSetting',
					component: () => import('@/views/setting/userSetting/index.vue'),
					name: 'settingUserAllSetting',
					redirect: '/setting/userSetting/allSetting/list',
					meta: {
						title: '用户管理'
					},
					children: [{
						path: '/setting/userSetting/allSetting/list',
						component: () => import('@/views/setting/userSetting/list/index.vue'),
						name: 'settingUserAllSettingList',
						meta: {
							title: '用户管理',
							isShow: true,
							activeMenu: '/setting/userSetting/allSetting'
						},
					}]
				},
				{
					path: '/setting/roleSetting/allSetting',
					component: () => import('@/views/setting/roleSetting/index.vue'),
					name: 'settingRoleAllSetting',
					redirect: '/setting/roleSetting/allSetting/list',
					meta: {
						title: '角色管理'
					},
					children: [{
						path: '/setting/roleSetting/allSetting/list',
						component: () => import('@/views/setting/roleSetting/list/index.vue'),
						name: 'settingRoleAllSettingList',
						meta: {
							title: '角色管理',
							isShow: true,
							activeMenu: '/setting/roleSetting/allSetting'
						},
					}, {
						path: '/setting/roleSetting/editRole',
						component: () => import('@/views/setting/roleSetting/list/edit.vue'),
						name: 'settingRoleEdit',
						meta: {
							title: '角色编辑',
							isShow: false,
							activeMenu: '/setting/roleSetting/allSetting'
						},
					}]
				}
			]
		},
	]
}
export default talent