import layout from '@/layout/index.vue'
const home = {
	path: '/home',
	component: layout,
	name: 'home',
	redirect: '/home/dataSummary',
	meta: {
		title: '首页',
		isSideBar: false
	},
	children: [{
		path: '/home/dataSummary',
		component: () => import('@/views/home/dataSummary/index.vue'),
		name: 'dataSummary',
		redirect: '/home/dataSummary/dataCenter',
		meta: {
			title: '数据概览'
		},
		children: [{
			path: '/home/dataSummary/dataCenter',
			component: () => import('@/views/home/dataSummary/dataCenter/index.vue'),
			name: 'dataCenter',
			redirect: '/home/dataSummary/dataCenter/list',
			meta: {
				title: '数据中心'
			},
			children: [{
				path: '/home/dataSummary/dataCenter/list',
				component: () => import(
					'@/views/home/dataSummary/dataCenter/list.vue'),
				name: 'dataSummaryIndex',
				meta: {
					title: '数据中心',
					isShow: true,
					activeMenu: '/home/dataSummary/dataCenter'
				},
			}]
		}]
	}]
}
export default home