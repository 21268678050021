import layout from '@/layout/index.vue'
const opration = {
	path: '/operation',
	component: layout,
	name: 'operation',
	redirect: '/operation/dataSummary',
	meta: {
		title: '工作台',
		isSideBar: true
	},
	children: [{
			path: '/operation/dataSummary',
			component: () => import('@/views/operation/dataSummary/index.vue'),
			name: 'dataSummary',
			redirect: '/operation/dataSummary/dataCenter',
			isShow: true,
			meta: {
				title: '数据概览'
			},
			children: [{
				path: '/operation/dataSummary/dataCenter',
				component: () => import('@/views/operation/dataSummary/dataCenter/index.vue'),
				name: 'dataCenter',
				redirect: '/operation/dataSummary/dataCenter/list',
				meta: {
					title: '数据中心'
				},
				children: [{
					path: '/operation/dataSummary/dataCenter/list',
					component: () => import(
						'@/views/operation/dataSummary/dataCenter/list.vue'),
					name: 'dataSummaryIndex',
					meta: {
						title: '数据中心',
						isShow: true,
						activeMenu: '/operation/dataSummary/dataCenter'
					},
				}]
			}]
		}, {
			path: '/operation/resource',
			component: () => import('@/views/operation/resource/index.vue'),
			name: 'operationClassify',
			isShow: true,
			redirect: '/operation/resource/voiceCommunication',
			meta: {
				title: '语音通信'
			},
			children: [{
					path: '/operation/resource/voiceCommunication',
					component: () => import('@/views/operation/resource/voiceCommunication/index.vue'),
					name: 'voiceCommunication',
					redirect: '/operation/resource/voiceCommunication/list',
					meta: {
						title: '资源管理'
					},
					children: [{
							path: '/operation/resource/voiceCommunication/list',
							component: () => import(
								'@/views/operation/resource/voiceCommunication/list.vue'),
							name: 'resourceManagementList',
							meta: {
								title: '资源管理',
								isShow: true,
								activeMenu: '/operation/resource/voiceCommunication'
							},
						}, {
							path: '/operation/resource/voiceCommunication/add',
							component: () => import(
								'@/views/operation/resource/voiceCommunication/add.vue'),
							name: 'resourceManagementAdd',
							meta: {
								title: '新增资源',
								isShow: true,
								activeMenu: '/operation/resource/voiceCommunication'
							},
						}, {
							path: '/operation/resource/voiceCommunication/detail',
							component: () => import(
								'@/views/operation/resource/voiceCommunication/detail.vue'),
							name: 'resourceManagementDetail',
							meta: {
								title: '资源详情',
								isShow: true,
								activeMenu: '/operation/resource/voiceCommunication'
							},
						},
						{
							path: '/operation/resource/voiceCommunication/edit',
							component: () => import(
								'@/views/operation/resource/voiceCommunication/edit.vue'),
							name: 'resourceManagementEdit',
							meta: {
								title: '编辑资源',
								isShow: true,
								activeMenu: '/operation/resource/voiceCommunication'
							},
						},
					]
				},
				// {
				// 	path: '/operation/resource/locallineManagement',
				// 	component: () => import('@/views/operation/resource/locallineManagement/index.vue'),
				// 	name: 'locallineManagement',
				// 	redirect: '/operation/resource/locallineManagement/list',
				// 	meta: {
				// 		title: '本地线路管理'
				// 	},
				// 	children: [{
				// 			path: '/operation/resource/locallineManagement/list',
				// 			component: () => import(
				// 				'@/views/operation/resource/locallineManagement/list.vue'),
				// 			name: 'locallineManagementList',
				// 			meta: {
				// 				title: '本地线路管理',
				// 				isShow: true,
				// 				activeMenu: '/operation/resource/locallineManagement'
				// 			},
				// 		},
				// 		{
				// 			path: '/operation/resource/locallineManagement/add',
				// 			component: () => import(
				// 				'@/views/operation/resource/locallineManagement/add.vue'),
				// 			name: 'resourceManagementAdd',
				// 			meta: {
				// 				title: '新增本地线路',
				// 				isShow: true,
				// 				activeMenu: '/operation/resource/locallineManagement'
				// 			},
				// 		},
				// 	]
				// },
				// {
				// 	path: '/operation/resource/assignedLocalline',
				// 	component: () => import('@/views/operation/resource/assignedLocalline/index.vue'),
				// 	name: 'assignedLocalline',
				// 	redirect: '/operation/resource/assignedLocalline/list',
				// 	meta: {
				// 		title: '已分配线路管理'
				// 	},
				// 	children: [{
				// 		path: '/operation/resource/assignedLocalline/list',
				// 		component: () => import(
				// 			'@/views/operation/resource/assignedLocalline/list.vue'),
				// 		name: 'assignedLocallineList',
				// 		meta: {
				// 			title: '已分配线路管理',
				// 			isShow: true,
				// 			activeMenu: '/operation/resource/assignedLocalline'
				// 		},
				// 	}]
				// },
			]
		},
		{
			path: '/operation/numberManagement',
			component: () => import('@/views/operation/numberManagement/index.vue'),
			name: 'numberManagement',
			isShow: true,
			meta: {
				title: '工作号管理'
			},
			children: [{
					path: '/operation/numberManagement/numCompany',
					component: () => import('@/views/operation/numberManagement/numCompany/index.vue'),
					name: 'numCompany',
					redirect: '/operation/numberManagement/numCompany/list',
					meta: {
						title: '工作号企业'
					},
					children: [{
						path: '/operation/numberManagement/numCompany/list',
						component: () => import(
							'@/views/operation/numberManagement/numCompany/list.vue'),
						name: 'numCompanyList',
						meta: {
							title: '工作号企业',
							isShow: true,
							activeMenu: '/operation/numberManagement/numCompany'
						},
					}]
				}, {
					path: '/operation/numberManagement/allNumber',
					component: () => import(
						'@/views/operation/numberManagement/allNumber/index.vue'),
					name: 'numberManagementAll',
					redirect: '/operation/numberManagement/allNumber/list',
					meta: {
						title: '号码池'
					},
					children: [{
						path: '/operation/numberManagement/allNumber/list',
						component: () => import(
							'@/views/operation/numberManagement/allNumber/list.vue'),
						name: 'allNumber',
						meta: {
							title: '号码池',
							isShow: true,
							activeMenu: '/operation/numberManagement/allNumber'
						},
					}]
				},
				{
					path: '/operation/numberManagement/workNumAssigned',
					component: () => import('@/views/operation/numberManagement/workNumAssigned/index.vue'),
					name: 'workNumAssigned',
					redirect: '/operation/numberManagement/workNumAssigned/list',
					meta: {
						title: '已分配号码'
					},
					children: [{
						path: '/operation/numberManagement/workNumAssigned/list',
						component: () => import(
							'@/views/operation/numberManagement/workNumAssigned/list.vue'),
						name: 'workNumAssignedList',
						meta: {
							title: '已分配号码',
							isShow: true,
							activeMenu: '/operation/numberManagement/workNumAssigned'
						},
					}]
				},
				{
					path: '/operation/numberManagement/workNumMarked',
					component: () => import('@/views/operation/numberManagement/workNumMarked/index.vue'),
					name: 'workNum',
					redirect: '/operation/numberManagement/workNumMarked/list',
					meta: {
						title: '已标记号码'
					},
					children: [{
						path: '/operation/numberManagement/workNumMarked/list',
						component: () => import(
							'@/views/operation/numberManagement/workNumMarked/list.vue'),
						name: 'workNumList',
						meta: {
							title: '已标记号码',
							isShow: true,
							activeMenu: '/operation/numberManagement/workNumMarked'
						},
					}]
				},
				{
					path: '/operation/numberManagement/workNumWillexpire',
					component: () => import(
						'@/views/operation/numberManagement/workNumWillexpire/index.vue'),
					name: 'workNumWillexpire',
					redirect: '/operation/numberManagement/workNumWillexpire/list',
					meta: {
						title: '到期号码'
					},
					children: [{
						path: '/operation/numberManagement/workNumWillexpire/list',
						component: () => import(
							'@/views/operation/numberManagement/workNumWillexpire/list.vue'),
						name: 'workNumWillexpireList',
						meta: {
							title: '到期号码',
							isShow: true,
							activeMenu: '/operation/numberManagement/workNumWillexpire'
						},
					}]
				}
			]
		},
		{
			path: '/operation/voicePoolManagement',
			component: () => import('@/views/operation/voicePoolManagement/index.vue'),
			name: 'voicePoolManagement',
			isShow: false,
			meta: {
				title: '语音池管理'
			},
			children: [{
					path: '/operation/voicePoolManagement/openedNum',
					component: () => import('@/views/operation/voicePoolManagement/openedNum/index.vue'),
					name: 'openedNum',
					redirect: '/operation/voicePoolManagement/openedNum/list',
					meta: {
						title: '已开通号码'
					},
					children: [{
						path: '/operation/voicePoolManagement/openedNum/list',
						component: () => import(
							'@/views/operation/voicePoolManagement/openedNum/list.vue'),
						name: 'openedNumList',
						meta: {
							title: '已开通号码',
							isShow: true,
							activeMenu: '/operation/voicePoolManagement/openedNum'
						},
					}]
				}, {
					path: '/operation/voicePoolManagement/unsubscribeNum',
					component: () => import(
						'@/views/operation/voicePoolManagement/unsubscribeNum/index.vue'),
					name: 'unsubscribeNum',
					redirect: '/operation/voicePoolManagement/unsubscribeNum/list',
					meta: {
						title: '已退订号码'
					},
					children: [{
						path: '/operation/voicePoolManagement/unsubscribeNum/list',
						component: () => import(
							'@/views/operation/voicePoolManagement/unsubscribeNum/list.vue'),
						name: 'unsubscribeNumList',
						meta: {
							title: '已退订号码',
							isShow: true,
							activeMenu: '/operation/voicePoolManagement/unsubscribeNum'
						},
					}]
				}
			]
		},
		{
			path: '/operation/ringbackNumber',
			component: () => import('@/views/operation/ringbackNumber/index.vue'),
			name: 'ringbackNumber',
			isShow: true,
			meta: {
				title: '回铃号管理'
			},
			children: [{
					path: '/operation/ringbackNumber/allNumber',
					component: () => import(
						'@/views/operation/ringbackNumber/allNumber/index.vue'),
					name: 'ringbackNumberAll',
					redirect: '/operation/ringbackNumber/allNumber/list',
					meta: {
						title: '号码池'
					},
					children: [{
						path: '/operation/ringbackNumber/allNumber/list',
						component: () => import(
							'@/views/operation/ringbackNumber/allNumber/list.vue'),
						name: 'ringbackNumberAllList',
						meta: {
							title: '号码池',
							isShow: true,
							activeMenu: '/operation/ringbackNumber/allNumber'
						},
					}]
				},
				{
					path: '/operation/ringbackNumber/assigned',
					component: () => import('@/views/operation/ringbackNumber/assigned/index.vue'),
					name: 'assignedNum',
					redirect: '/operation/ringbackNumber/assigned/list',
					meta: {
						title: '已分配号码'
					},
					children: [{
						path: '/operation/ringbackNumber/assigned/list',
						component: () => import(
							'@/views/operation/ringbackNumber/assigned/list.vue'),
						name: 'assignedNumList',
						meta: {
							title: '已分配号码',
							isShow: true,
							activeMenu: '/operation/ringbackNumber/assigned'
						},
					}]
				},
				{
					path: '/operation/ringbackNumber/marked',
					component: () => import('@/views/operation/ringbackNumber/marked/index.vue'),
					name: 'markedNum',
					redirect: '/operation/ringbackNumber/marked/list',
					meta: {
						title: '已标记号码'
					},
					children: [{
						path: '/operation/ringbackNumber/marked/list',
						component: () => import(
							'@/views/operation/ringbackNumber/marked/list.vue'),
						name: 'markedNumList',
						meta: {
							title: '已标记号码',
							isShow: true,
							activeMenu: '/operation/ringbackNumber/marked'
						},
					}]
				}
			]
		},
		{
			path: '/operation/companyManagement',
			component: () => import('@/views/operation/companyManagement/index.vue'),
			name: 'companyManagement',
			isShow: true,
			meta: {
				title: '企业管理'
			},
			children: [{
				path: '/operation/companyManagement/companyList',
				component: () => import('@/views/operation/companyManagement/company/index.vue'),
				name: 'companyReportAll',
				redirect: '/operation/companyManagement/company/list',
				meta: {
					title: '报备企业'
				},
				children: [{
					path: '/operation/companyManagement/company/list',
					component: () => import(
						'@/views/operation/companyManagement/company/list.vue'),
					name: 'companyList',
					meta: {
						title: '报备企业',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyList'
					},
				}, {
					path: '/operation/companyManagement/company/add',
					component: () => import(
						'@/views/operation/companyManagement/company/add.vue'),
					name: 'companyAdd',
					meta: {
						title: '新增企业',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyAdd'
					},
				}, {
					path: '/operation/companyManagement/company/edit',
					component: () => import(
						'@/views/operation/companyManagement/company/edit.vue'),
					name: 'companyEdit',
					meta: {
						title: '编辑企业',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyEdit'
					},
				}, {
					path: '/operation/companyManagement/company/detail',
					component: () => import(
						'@/views/operation/companyManagement/company/detail.vue'),
					name: 'companyDetail',
					meta: {
						title: '企业详情',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyDetail'
					},
				}]
			}, {
				path: '/operation/companyManagement/companyCallerList',
				component: () => import(
					'@/views/operation/companyManagement/company/companyCallerList.vue'),
				name: 'companyCallerAll',
				redirect: '/operation/companyManagement/company/companyCallerList',
				meta: {
					title: '主叫管理'
				},
				children: [{
					path: '/operation/companyManagement/company/companyCallerList',
					component: () => import(
						'@/views/operation/companyManagement/company/companyCallerList.vue'
					),
					name: 'companyCallerList',
					meta: {
						title: '主叫管理',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyCallerList'
					},
				}]
			}]
		},
		// {
		// 	path: '/operation/reportingMaterials',
		// 	component: () => import('@/views/operation/reportingMaterials/index.vue'),
		// 	name: 'reportingMaterials',
		// 	meta: {
		// 		title: '报备材料'
		// 	},
		// 	children: [{
		// 		path: '/operation/reportingMaterials/templatelist',
		// 		component: () => import('@/views/operation/reportingMaterials/template/index.vue'),
		// 		name: 'templateAll',
		// 		redirect: '/operation/reportingMaterials/template/list',
		// 		meta: {
		// 			title: '材料模板'
		// 		},
		// 		children: [{
		// 			path: '/operation/reportingMaterials/template/list',
		// 			component: () => import(
		// 				'@/views/operation/reportingMaterials/template/list.vue'),
		// 			name: 'templateList',
		// 			meta: {
		// 				title: '材料模板',
		// 				isShow: true,
		// 				activeMenu: '/operation/reportingMaterials/templatelist'
		// 			},
		// 		}]
		// 	}, {
		// 		path: '/operation/reportingMaterials/templateFieldlist',
		// 		component: () => import('@/views/operation/reportingMaterials/templateField/index.vue'),
		// 		name: 'templateFieldAll',
		// 		redirect: '/operation/reportingMaterials/templateField/list',
		// 		meta: {
		// 			title: '模板字段'
		// 		},
		// 		children: [{
		// 			path: '/operation/reportingMaterials/templateField/list',
		// 			component: () => import(
		// 				'@/views/operation/reportingMaterials/templateField/index.vue'),
		// 			name: 'templateFieldList',
		// 			meta: {
		// 				title: '模板字段',
		// 				isShow: true,
		// 				activeMenu: '/operation/reportingMaterials/templateFieldlist'
		// 			},
		// 		}, {
		// 			path: '/operation/reportingMaterials/templateField/add',
		// 			component: () => import(
		// 				'@/views/operation/reportingMaterials/templateField/add.vue'),
		// 			name: 'templateFieldAdd',
		// 			meta: {
		// 				title: '新增字段',
		// 				isShow: true,
		// 				activeMenu: '/operation/reportingMaterials/add'
		// 			},
		// 		}, {
		// 			path: '/operation/reportingMaterials/templateField/edit',
		// 			component: () => import(
		// 				'@/views/operation/reportingMaterials/templateField/edit.vue'),
		// 			name: 'templateFieldAdd',
		// 			meta: {
		// 				title: '修改字段',
		// 				isShow: true,
		// 				activeMenu: '/operation/reportingMaterials/edit'
		// 			},
		// 		}]
		// 	}]
		// },
		{
			path: '/operation/financeManagement',
			component: () => import('@/views/operation/financeManagement/index.vue'),
			name: 'financeManagement',
			isShow: true,
			meta: {
				title: '财务管理'
			},
			children: [{
					path: '/operation/financeManagement/supplierBalanceList',
					component: () => import(
						'@/views/operation/financeManagement/supplierBalance/index.vue'),
					name: 'supplierBalanceAll',
					redirect: '/operation/financeManagement/supplierBalance/list',
					meta: {
						title: '供应商余额流水明细'
					},
					children: [{
						path: '/operation/financeManagement/supplierBalance/list',
						component: () => import(
							'@/views/operation/financeManagement/supplierBalance/list.vue'),
						name: 'supplierBalanceList',
						meta: {
							title: '供应商余额流水明细',
							isShow: true,
							activeMenu: '/operation/financeManagement/supplierBalanceList'
						},
					}]
				},
				// {
				// 	path: '/operation/financeManagement/agentBalanceList',
				// 	component: () => import('@/views/operation/financeManagement/agentBalance/index.vue'),
				// 	name: 'agentBalanceAll',
				// 	redirect: '/operation/financeManagement/agentBalance/list',
				// 	meta: {
				// 		title: '渠道余额流水明细'
				// 	},
				// 	children: [{
				// 		path: '/operation/financeManagement/agentBalance/list',
				// 		component: () => import(
				// 			'@/views/operation/financeManagement/agentBalance/list.vue'),
				// 		name: 'agentBalance',
				// 		meta: {
				// 			title: '渠道余额流水明细',
				// 			isShow: true,
				// 			activeMenu: '/operation/financeManagement/agentBalanceList'
				// 		},
				// 	}]
				// }
			]
		},
		{
			path: '/operation/cdrManagement',
			component: () => import('@/views/operation/cdrManagement/index.vue'),
			name: 'cdrManagement',
			isShow: true,
			meta: {
				title: '话单管理'
			},
			children: [{
				path: '/operation/cdrManagement/cdrList',
				component: () => import('@/views/operation/cdrManagement/cdr/index.vue'),
				name: 'cdrAll',
				redirect: '/operation/cdrManagement/cdr/list',
				meta: {
					title: '话单管理'
				},
				children: [{
					path: '/operation/cdrManagement/cdr/list',
					component: () => import(
						'@/views/operation/cdrManagement/cdr/list.vue'),
					name: 'cdrList',
					meta: {
						title: '话单管理',
						isShow: true,
						activeMenu: '/operation/cdrManagement/cdrList'
					},
				}]
			}]
		},
		{
			path: '/operation/systemManagement',
			component: () => import('@/views/operation/systemManagement/index.vue'),
			name: 'systemManagement',
			isShow: true,
			meta: {
				title: '系统管理'
			},
			children: [{
				path: '/operation/systemManagement/userList',
				component: () => import('@/views/operation/systemManagement/user/index.vue'),
				name: 'userManage',
				redirect: '/operation/systemManagement/user/list',
				meta: {
					title: '用户管理'
				},
				children: [{
					path: '/operation/systemManagement/user/list',
					component: () => import(
						'@/views/operation/systemManagement/user/list.vue'),
					name: 'userList',
					meta: {
						title: '用户管理',
						isShow: true,
						activeMenu: '/operation/systemManagement/userList'
					},
				}]
			}, {
				path: '/operation/systemManagement/roleList',
				component: () => import('@/views/operation/systemManagement/role/index.vue'),
				name: 'roleManage',
				redirect: '/operation/systemManagement/role/list',
				meta: {
					title: '角色管理'
				},
				children: [{
					path: '/operation/systemManagement/role/list',
					component: () => import(
						'@/views/operation/systemManagement/role/list.vue'),
					name: 'roleList',
					meta: {
						title: '角色管理',
						isShow: true,
						activeMenu: '/operation/systemManagement/roleList'
					},
				}]
			}, {
				path: '/operation/systemManagement/logsList',
				component: () => import('@/views/operation/systemManagement/logs/index.vue'),
				name: 'companyAll',
				redirect: '/operation/systemManagement/logs/list',
				meta: {
					title: '日志管理'
				},
				children: [{
					path: '/operation/systemManagement/logs/list',
					component: () => import(
						'@/views/operation/systemManagement/logs/list.vue'),
					name: 'logsList',
					meta: {
						title: '日志管理',
						isShow: true,
						activeMenu: '/operation/systemManagement/logsList'
					},
				}]
			}, {
				path: '/operation/systemManagement/developConfig',
				component: () => import('@/views/operation/systemManagement/developConfig/index.vue'),
				name: 'developConfig',
				redirect: '/operation/systemManagement/developConfig/config',
				meta: {
					title: '开发者配置'
				},
				children: [{
					path: '/operation/systemManagement/developConfig/config',
					component: () => import(
						'@/views/operation/systemManagement/developConfig/config.vue'),
					name: 'config',
					meta: {
						title: '开发者配置',
						isShow: true,
						activeMenu: '/operation/systemManagement/developConfig'
					},
				}]
			}]
		}
	]
}
export default opration