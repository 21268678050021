// 封装axios的post、get、all方法
import request from './request'
import objKeySort from "@/utils/sign";
import router from '@/router/index.js';
import {
    handlerHttpError
} from '@/axios/error'
import {
    Message
} from 'element-ui'

export function get (url, params) {
    var newParams = {
        ...params
    }
    newParams.sign = objKeySort(newParams)
    return new Promise(resolve => {
        request({
            method: 'get',
            url: url,
            params: newParams
        })
            .then(
                response => {
                    if (response.data.code != '0000') {
                        handlerHttpError(response.data)
                    }
                    resolve(response.data)
                },
                err => {
                    throw err.response.code
                }
            )
            .catch(error => {
                if (error === 500 || error === 504) {
                    // console.log(error)
                    // Message.error(`网络错误,请稍后重试！${error.statusCode}`)
                }
            })
    })
}

export function post (url, data) {
    var newData = {
        ...data
    }
    newData.sign = objKeySort(newData)
    return new Promise(resolve => {
        request({
            method: 'post',
            url: url,
            data: newData
        })
            .then(
                response => {
                    if (response.data.code != '0000') {
                        handlerHttpError(response.data)
                    }
                    resolve(response.data)
                },
                err => {
                    throw err.response.code
                }
            )
            .catch(error => {
                // console.log(error)
                if (error === 500 || error === 504) {
                    // console.log(error)
                    // Message.error(`网络错误,请稍后重试！${error.statusCode}`)
                }
            })
    })
}

export function del (url, data) {
    var newData = {
        ...data
    }
    newData.sign = objKeySort(newData)
    return new Promise(resolve => {
        request({
            method: 'delete',
            url: url,
            data: newData
        })
            .then(
                response => {
                    resolve(response.data)
                },
                err => {
                    throw err.response.code
                }
            )
            .catch(error => {
                // console.log(error)
            })
    })
}

export function put (url, data) {
    var newData = {
        ...data
    }
    newData.sign = objKeySort(newData)
    return new Promise(resolve => {
        request({
            method: 'put',
            url: url,
            data: newData
        })
            .then(
                response => {
                    resolve(response.data)
                },
                err => {
                    throw err.response.code
                }
            )
            .catch(error => {
                // console.log(error)
            })
    })
}

export function upload (url, fileList) {
    return new Promise((resolve, reject) => {
        request({
            method: 'post',
            url: url,
            data: fileList,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(
                response => {
                    if (response.data.code != '0000') {
                        handlerHttpError(response.data)
                    }
                    resolve(response.data)
                },
                err => {
                    reject(err)
                }
            )
            .catch(error => {
                // console.log(error)
            })
    })
}

/**
       * postFormData方法，对应post请求，用来提交文件+数据
       * @param {String} url [请求的url地址]
       * @param {Object} data [请求时携带的参数]
       */
export function postFormData (url, data) {
    var newData = {
        ...data
    }
    newData.sign = objKeySort(newData)
    return new Promise((resolve, reject) => {
        request({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            url: url,
            method: 'post',
            data: newData,
            timeout: 30000,
            transformRequest: [
                function (datas) {
                    // 在请求之前对data传参进行格式转换
                    const formData = new FormData()
                    Object.keys(datas).forEach((key) => {
                        formData.append(key, datas[key])
                    })
                    return formData
                }
            ]
        })
            .then((res) => {
                if (res.data.code != '0000') {
                    handlerHttpError(res.data)
                    reject(res.data)
                } else {
                    resolve(res.data)
                }
            })
            .catch((err) => {
                if (err.message.includes('timeout')) {
                    Notify('请求超时，请刷新页面再试')
                }
                reject(err)
            })
    })
}

export function download (type, url, data) {
    var newData = {
        ...data
    }
    newData.sign = objKeySort(newData)
    return new Promise((resolve, reject) => {
        request({
            method: type,
            url: url,
            [type === 'get' ? 'params' : 'data']: newData,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(
                response => {
                    if (!response.headers['content-disposition'] && url != "/agent/publics/downTemplate") {
                    	handlerHttpError({
                    		code: '4000',
                    		msg: '数据为空'
                    	})
                    }
                    resolve(response)
                },
                err => {
					reject(err)
                    throw err.response.code
                }
            )
            .catch(error => {
                reject(error); // 拒绝 Promise，以便上层处理
            })
    })
}
