<template>
	<div class="qs-menu-wrapper">
		<ul class="qs-menu-ul clearfix">
			<li :class="isZoom?'qs-zoom':'qs-zoom-ac'" @click="_debounce(handleIsZoom, 500)"></li>
			<li class="qs-menu-li">
				<Bread></Bread>
			</li>
			<!-- <li class="qs-menu-li" v-for="(item, index) in addRouters" :key="index">
                <div :class="item.path == parentMenu ? 'qs-menu-text qs-menu-ac' : 'qs-menu-text'" @click="handleJump(item)">{{ item.meta.title }}</div>
            </li> -->
		</ul>
		<!-- <div class="notice-box">
			<span class="notice-icon">系统公告：</span>
			<ul ref="noticecList" class="notice-list">
				<li class="notice-item" v-for="(notice, index) in notices" :key="index"><a class="substring"
						href="">{{ notice }}</a></li>
			</ul>
		</div> -->
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	import Bread from '@/components/Tabs/bread.vue';
	export default {
		name: 'topMenu',
		props: ['parentMenu'],
		components: {
			Bread
		},
		data() {
			return {
				params: {
					mark: 'talent',
				},
				currentIndex: 0,
				timer: null,
				notices: [
					"111公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告",
					"222公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告",
					"333公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告公告",
					// 添加更多公告...
				],
			}
		},
		computed: {
			...mapState({
				addRouters: (state) => state.routingTable.addRouters,
				isSideBar: (state) => state.layout.isSideBar,
				isZoom: (state) => state.layout.isZoom,
			}),
			currentRoute() {
				return this.$route
			},
		},
		mounted() {
			// this.startScroll();
		},
		beforeDestroy() {
			clearInterval(this.timer);
		},
		methods: {
			...mapMutations({
				SET_ISZOOM: 'layout/SET_ISZOOM',
				SET_OPERATE_AUTH: 'common/SET_OPERATE_AUTH',
			}),
			...mapActions({
				getOperateAuth: 'common/getOperateAuth',
			}),
			startScroll() {
				this.timer = setInterval(() => {
					this.currentIndex++;
					if (this.currentIndex === this.notices.length) {
						this.currentIndex = 0;
					}
					this.scrollToIndex(this.currentIndex);
				}, 3000);
			},
			scrollToIndex(index) {
				const list = this.$refs.noticecList;
				const itemHeight = list.children[0].offsetHeight;
				list.style.transform = `translateY(-${itemHeight * index}px)`;
			},
			handleIsZoom() {
				// isSideBar false 不展示左侧菜单 true 展示左侧菜单
				if (this.isSideBar) {
					this.SET_ISZOOM(!this.isZoom)
				}
			},
			handleJump(item) {
				// this.getOperateAuth();
				// // console.log('点击顶部导航：', item.path)
				this.$router.push(item.path)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.qs-menu-wrapper {
		width: 60%;
		height: 60px;
		overflow-x: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&::-webkit-scrollbar {
			display: none;
		}

		.qs-menu-ul {
			white-space: nowrap;
			height: 60px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: start;

			.qs-zoom {
				width: 18px;
				height: 16px;
				cursor: pointer;
				display: inline-block;
				background: url('@assets/images/layout/zoom_in_1.png') no-repeat center center;
				background-size: 100% 100%;
				margin: 0 15px;
			}

			.qs-zoom-ac {
				width: 18px;
				height: 16px;
				cursor: pointer;
				display: inline-block;
				margin: 0 15px;
				background: url('@assets/images/layout/zoom_out_1.png') no-repeat center center;
				background-size: 100% 100%;
			}

			.qs-menu-li {
				height: 60px;

				.qs-menu-text {
					display: block;
					color: $textColor2;
					font-size: 14px;
					font-weight: 700;
					padding: 0 25px;
					height: 60px;
					line-height: 60px;
					cursor: pointer;
					position: relative;

					&.qs-menu-ac {
						position: relative;
						background-image: linear-gradient(60deg,
								$themeColor,
								$themeColor );
						background-clip: text;
						color: transparent;

						&::before {
							content: '';
							position: absolute;
							bottom: -1px;
							left: 50%;
							transform: translateX(-50%);
							width: 32px;
							height: 3px;
							background: linear-gradient(to right,
									$themeColor,
									$themeColor );
						}
					}
				}
			}
		}

		.notice-box {
			width: 50%;
			background: #F2F6FF;
			height: 60%;
			border-radius: 3px;
			display: flex;
			align-items: center;
			justify-content: start;

			.notice-icon {
				display: block;
				width: 115px;
				height: 36px;
				line-height: 36px;
				font-size: 14px;
				color: #2469FF;
				background: url('@/assets/images/layout/notice.png') 13px center no-repeat;
				background-size: 15px 13px;
				padding-left: 36px;
			}

			.notice-list {
				width: calc(100% - 115px);

				.notice-item {
					height: 100%;

					a {
						display: block;
						width: 100%;
						height: 36px;
						line-height: 36px;
						font-size: 14px;
						box-sizing: border-box;
						padding-right: 15px;
					}
				}
			}
		}
	}
</style>