import vue from 'vue'
//获取地区
function getAreaList(data) {
	return vue.$get('/agent/publics/getAreaList', data)
}
// //获取供应商下拉列表
// function getSupplierList(data) {
// 	return vue.$get('/admin/publics/getSupplierList', data)
// }
// //获取报备材料模板下拉
// function getProfileTemplateList(data) {
// 	return vue.$get('/admin/publics/getProfileTemplateList', data)
// }
//获取渠道下拉
// function getAgentList(data) {
// 	return vue.$get('/admin/publics/getAgentList', data)
// }
//获取本地线路下拉
function getLocalLineList(data) {
	return vue.$get('/agent/publics/getAreaList', data)
}
//获取企业下拉
function getCompanyListSelect(data) {
	return vue.$get('/agent/publics/getCompanyList', data)
}
//获取号码行内分配的企业下拉
// 
function getNumberCompanyList(data) {
	return vue.$get('/agent/publics/getNumberCompanyList', data)
}
//获取行业下拉
function getIndustryList(data) {
	return vue.$get('/agent/publics/getIndustryList', data)
}
//获取材料模板字段下拉
// function getProfileFieldList(data) {
// 	return vue.$get('/admin/publics/getProfileFieldList', data)
// }
//获取呼叫模式下拉  
function getCallModeList(data) {
	return vue.$get('/agent/publics/getCallModeList', data)
}
//获取资源下拉  
function getResourceList(data) {
	return vue.$get('/agent/publics/getResourceList', data)
}

// 获取角色下拉 
function getRoleList(data) {
	return vue.$get('/agent/publics/getRoleList', data)
}



//下载模板 
// function downTemplate (data) {
//     return vue.$get('/admin/publics/downTemplate', data)
// }
function downTemplate(data) {
	return vue.$download('get', '/agent/publics/downTemplate', data)
}

// 上传图片
function uploadImg(data) {
	return vue.$postFormData('/admin/upload/image', data)
}

// 上传文件
function uploadFile(data) {
	return vue.$postFormData('/agent/upload/file', data)
}

// 导入已开通号码
function importOpenedNum(data) {
	return vue.$postFormData('/agent/direct/import', data)
}

export {
	getAreaList,
	// getSupplierList,
	// getProfileTemplateList,
	// getAgentList,
	getLocalLineList,
	getCompanyListSelect,
	getNumberCompanyList,
	getIndustryList,
	// getProfileFieldList,
	getCallModeList,
	getResourceList,
	downTemplate,
	getRoleList,
	uploadImg,
	uploadFile,
	importOpenedNum
}