<template>
	<div :class="isZoom?'qs-logo':'qs-logo qs-logo-none'">
		<router-link :class="isZoom?'qs-login-text':'qs-login-text qs-login-text-white'"
			to="/">{{ platformName }}</router-link>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		name: 'logo',
		computed: {
			...mapState({
				platformName: (state) => state.common.platformName,
				isZoom: (state) => state.layout.isZoom
			})
		}
	}
</script>

<style lang="scss" scoped>
	.qs-logo {
		width: 235px;
		height: 60px;
		background: $headBgColor;
		color: $themeTextColor;
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		top: 0;
		cursor: pointer;
		transition: all 0.5s;

		&.qs-logo-none {
			width: 0;
		}


		.qs-login-text {
			display: block;
			width: 100%;
			height: 100%;
			line-height: 60px;
			padding-left: 25px;
			color: $themeTextColor;
			font-weight: bold;
			font-size: 20px;
			background: $menuBgColor;

			&.qs-login-text-white {
				display: none;
				color: $menuBgColor;
				background: #fff;
			}

		}
	}
</style>